<!-- 考试大类 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css">
  .table label {
    margin-bottom: 0;
  }

  /deep/.el-button--mini {
    height: 30px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import {
    getUserManagement,
    addUserManagement,
    getUserManagementDetails,
    updateUserManagement,
    getUserDetails,
    updatePermission,
    updatePassword,
  } from "@/api/admin/user/institutionManage.js";
  import {
    getDicts
  } from "@/api/common/dict.js";
  import YzSelect from "@/components/form/yzSelect.vue";
  import YzSelectMulit from "@/components/form/yzSelectMulit.vue";
  import YzSearch from "@/components/form/yzSearch.vue";
  import UserPromission from "@/components/user/user-promission.vue";
  import YzModal from "@/components/form/yzModal.vue";
  import {
    handleUserManagement
  } from "@/api/admin/user/institutionManage";
  import {
    updateUserManagementKyzt
  } from "@/api/admin/user/institutionManage";
  import {
    deleteBatchManagement
  } from "@/api/admin/user/institutionManage";
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      YzSelect,
      YzSelectMulit,
      UserPromission,
      YzSearch,
      YzModal,
    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin",
          },
          {
            text: "用户管理",
            active: true,
          },
        ],
        checked: true,
        defaultvalue: 3,
        currentpage: 3,
        showmodal: false,
        tableList: [],
        pageData: {
          nowPage: 1,
          pageSize: 20,
          total: 0,
          sczt: 0,
        },
        showPromission: false,
        dict: {},
        form: {},
        search: [],
        params: {},
        handleForm: {},
        handleYhzh: "",
        selectList: [],
        isIndeterminate: false,
      };
    },
    methods: {
      chooseAll(e) {
        this.selectList = e ?
          this.tableList.map((item) => {
            return item.yhzh;
          }) : [];
        this.isIndeterminate = e;
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList();
      },
      getList() {
        getUserManagement("org", this.pageData).then((res) => {
          if (res.status) {
            this.tableList = res.data;
            this.pageData.total = res.total;
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize = res.pageSize;
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      addUser() {
        addUserManagement(this.form).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.showmodal = false;
            this.getList();
          }
        });
      },
      editUser(yhzh) {
        getUserManagementDetails(yhzh).then((res) => {
          if (res.status) {
            this.form = res.data;
            this.showmodal = true;
          }
        });
      },
      updateUser() {
        updateUserManagement(this.form.yhzh, this.form).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "更新成功!",
            });
            this.showmodal = false;
            this.getList();
          }
        });
      },
      deleteUser(yhzh) {
        this.$confirm(`此操作将永久删除该【${yhzh}】的数据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteBatchManagement(yhzh).then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        });
      },
      ztUpdate(yhzh, kyzt) {
        updateUserManagementKyzt(yhzh, kyzt).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "更新成功!",
            });
            this.getList();
          }
        });
      },
      shUpdate(yhzh) {
        this.$refs.yzmodal.show();
        this.handleForm = {};
        this.handleYhzh = yhzh;
      },
      getPermission(yhzh) {
        getUserDetails(yhzh).then((res) => {
          if (res.status) {
            this.form = res.data;
            this.showPromission = true;
          }
        });
      },
      updatePermission(mid, mkqx) {
        updatePermission(mid, {
          mkqx: mkqx
        }).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "更新成功!",
            });
            this.showPromission = false;
            this.getList();
          }
        });
      },
      startUpdate(yhzh, mm) {
        updatePassword(yhzh, mm).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "更新成功",
            });
          }
        });
      },
      sfzjChange() {
        if (this.form.sfzjh && this.form.sfzjh.length >= 18) {
          this.form.mm = this.form.sfzjh.substring(12);
          this.$forceUpdate();
        }
      },
      handle() {
        handleUserManagement(this.handleYhzh, this.handleForm).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "审核成功!",
            });
            this.getList();
            this.$refs.yzmodal.hide();
          }
        });
      },
      handleCheckAllChange(e) {
        if (e) {
          this.selectList = this.tableList.map((item) => {
            item.checked = true;
            return item;
          });
        } else {
          this.tableList.forEach((item) => {
            item.checked = false;
          });
          this.selectList = [];
        }
        this.$forceUpdate();
      },
      handleCheckedCitiesChange(e, o) {
        if (e) {
          this.selectList.push(o);
        } else {
          this.selectList = this.selectList.filter((item) => {
            return item != o;
          });
        }
        this.$forceUpdate();
      },
      deleteBatch() {
        this.$confirm("此操作将永久删除这些数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteBatchManagement(this.selectList.join(",")).then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        });
      },
    },
    mounted() {
      this.getList();
      getDicts("dict_xzqh").then(res => {
        this.dict = res
        this.search = [{
          title: "姓名/账号/身份证件号",
          bound: "keyword",
          type: "input",
        }, ];
      })
    },
  };
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search :data="search" v-model="pageData" @seachList="searchClick"></yz-search>
                <b-button v-b-modal.addInstiUser @click="form = {jgdm: ''}" variant="outline-info"
                  class="flexList mr-2 condition"><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加用户
                </b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab">
                  <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
                <div class="border-blue export-tab">
                  <i class="iconfont icon-data mr-2"></i>dbf
                </div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <el-checkbox-group v-model="selectList">
                <table class="table light-table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 5%">
                        <el-checkbox @change="chooseAll" class="mr-1" :indeterminate="isIndeterminate">
                        </el-checkbox>全选
                      </th>
                      <th style="width: 8%">所属机构</th>
                      <th style="width: 10%">所属单位</th>
                      <th style="width: 7%">登录账号</th>
                      <th style="width: 6%">用户姓名</th>
                      <th style="width: 8%">职务</th>
                      <th style="width: 8%">用户类型</th>
                      <th style="width: 8%">联系电话</th>
                      <th style="width: 12%">所属地区</th>
                      <th style="width: 5%">可用状态</th>
                      <th style="width: 5%">审核状态</th>
                      <th style="width: 4%">操作</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(obj, index) in tableList" :key="index">
                      <td>
                        <el-checkbox :label="obj.yhzh" class="mr-1">{{
                          (pageData.pageNum - 1) * pageData.pageSize +  (index + 1)
                        }}</el-checkbox>
                      </td>
                      <td>{{ obj.jgmc }}</td>
                      <td>
                        <a href="" class="font-blue">{{ obj.dwmc }}</a>
                      </td>
                      <td>
                        <a href="javascript:;" @click="$yzAdminUserInfo().show(obj.yhzh)"
                          class="font-blue">{{ obj.yhzh }}</a>
                      </td>
                      <td>{{ obj.xm }}</td>
                      <td>{{ obj.zw }}</td>
                      <td>{{ obj.yhlb }}</td>
                      <td>{{ obj.yddh }}</td>
                      <td>{{ obj.djsmc }}</td>
                      <td>
                        <b-form-checkbox v-model="obj.kyzt" switch class="switch-check"
                          @change="ztUpdate(obj.yhzh, obj.kyzt)">
                        </b-form-checkbox>
                      </td>
                      <td>
                        <a href="javascript:;" @click="shUpdate(obj.yhzh, obj.shzt)">
                          <div class="text-success" v-if="obj.shzt == 1">
                            <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i>通过
                          </div>
                          <div class="text-danger" v-else-if="obj.shzt == 2">
                            <i class="iconfont icon-ze-clear text-danger mr-2"></i>未通过
                          </div>
                          <div class="text-info" v-else>
                            <i class="iconfont icon-icon_minus-circled mr-2"></i>待审核
                          </div>
                        </a>
                      </td>
                      <td class="tab-icon">
                        <a href="javascript:;" @click="editUser(obj.yhzh)"><i
                            class="iconfont icon-edit-two align-middle mr-1"></i></a>
                        <i @click="getPermission(obj.yhzh)" class="iconfont icon-key align-middle mr-1"></i><i
                          @click="deleteUser(obj.yhzh)"
                          class="iconfont icon-riLine-delete-bin-line align-middle mr-1"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </el-checkbox-group>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->
    <!--添加机构用户-->
    <b-modal id="addInstiUser" v-model="showmodal" centered :title="form.sid ? '更新机构用户' : '添加机构用户'" size="lg"
      title-class="font-18" hide-footer>
      <div>
        <div class="d-flex check-distri mb-3" v-if="!form.sid">
          <label>登录账号：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="text" v-model="form.yhzh" placeholder="登录账号" class="form-control w-70 h30" />
            <span class="grey-time ml-2">(登录账号)</span>
          </div>
        </div>
        <div class="d-flex check-distri mb-3">
          <label>身份证号：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="text" v-model="form.sfzjh" @input="sfzjChange" placeholder="请输入身份证号"
              class="form-control w-70 h30" />
            <span class="grey-time ml-2">(身份证号)</span>
          </div>
        </div>
        <div class="d-flex check-distri mb-3" v-if="!form.sid">
          <label>用户密码：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="text" v-model="form.mm" placeholder="请输入用户密码" class="form-control w-70 h30" />
            <span class="grey-time ml-2">(默认为身份证后六位)</span>
          </div>
        </div>
        <div class="d-flex check-distri mb-3">
          <label style="width: 9%">职务：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="tel" v-model="form.zw" placeholder="职务" class="form-control w-70 h30" />
            <span class="grey-time ml-2">(职务)</span>
          </div>
        </div>
        <div class="d-flex check-distri mb-3">
          <div class="col-sm-8 p-0 flexList">
            <label>所属地区：</label>
            <div class="flexList" style="flex: 1">
              <yz-select-mulit v-model="form" :dict="dict.dict_xzqh" :bounds="[
                  ['ssdm', 'ssmc'],
                  ['djsdm', 'djsmc'],
                  ['qxdm', 'qxmc'],
                ]"></yz-select-mulit>
            </div>
          </div>
          <div class="grey-time ml-2 flexList">
            (所在地区用以划分审核范围，非必须字段)
          </div>
        </div>
        <div class="d-flex check-distri mb-3">
          <label style="width: 9%">联系人：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="text" v-model="form.xm" placeholder="联系人姓名" class="form-control w-70 h30" />
            <span class="grey-time ml-2">(联系人)</span>
          </div>
        </div>
        <div class="d-flex check-distri mb-3">
          <label>联系电话：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="tel" v-model="form.yddh" placeholder="联系电话" class="form-control w-70 h30" />
            <span class="grey-time ml-2">(联系电话)</span>
          </div>
        </div>
        <div class="d-flex check-distri mb-3">
          <label>电子邮箱：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="email" v-model="form.dzxx" placeholder="联系人电子邮箱" class="form-control w-70 h30" />
            <span class="grey-time ml-2">(电子邮箱)</span>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <button type="button" class="btn btn-info h30 w-md mr-3" @click="form.sid ? updateUser() : addUser()">
          {{ form.sid ? "确定修改" : "确定添加" }}
        </button>
        <button type="button" class="btn btn-secondary h30 w-md" @click="showmodal = false">
          取消
        </button>
      </div>
    </b-modal>

    <!-- 弹窗结束-->

    <!-- 弹窗开始 -->
    <!--添加机构用户-->
    <b-modal v-model="showPromission" centered title="修改权限" size="lg" title-class="font-18" hide-footer>
      <div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0">用户权限：</label>
          <user-promission v-model="form.mkqx"></user-promission>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 9%">密码：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="text" v-model="form.mm" placeholder="新密码" class="form-control w-50 h30" />
            <button style="margin-left: 10px" type="button" class="btn btn-info h30 w-md mr-3"
              @click="startUpdate(form.yhzh, form.mm)">
              确定修改密码
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <button type="button" class="btn btn-info h30 w-md mr-3" @click="updatePermission(form.mid, form.mkqx)">
          确定修改
        </button>
        <button type="button" class="btn btn-secondary h30 w-md" @click="showPromission = false">
          取消
        </button>
      </div>
    </b-modal>

    <!-- 弹窗结束-->

    <YzModal ref="yzmodal" title="用户审核" :bound="['shzt', 'shbz']" v-model="handleForm" @change="handle"></YzModal>
  </Layout>
</template>
